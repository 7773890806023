//React
import React from "react"

//Icons
import { FaCheck, FaTimes } from "react-icons/fa"

const Snippet = (props) => (
  <div style={{ minHeight: 35 }}>
    {props.password ? (
      <div style={{ minHeight: 35 }}>
        <div className="d-flex flex-column align-items-start">
          <div class="password-message text-right">
            {props.password === props.password2 ? (
              <div class="d-flex flex-row align-items-center justify-content-center dkgreen-1">
                <FaCheck size={11} /> <div class="ml-1">Passwords match</div>
              </div>
            ) : (
              <div class="d-flex flex-row align-items-center justify-content-center red-1">
                <FaTimes size={11} />{" "}
                <div class="ml-1">Passwords don't match</div>
              </div>
            )}
          </div>
          <div class="password-attributes-message text-left">
            {props.password.length >= 8 ? (
              <div class="d-flex flex-row align-items-center justify-content-center dkgreen-1">
                <FaCheck size={11} />{" "}
                <div class="ml-1">Password has 8+ characters</div>
              </div>
            ) : (
              <div class="d-flex flex-row align-items-center justify-content-center red-1">
                <FaTimes size={11} />{" "}
                <div class="ml-1">Password must be 8+ characters</div>
              </div>
            )}
          </div>
          <div class="password-attributes-message text-left">
            {/[a-z]/.test(props.password) ? (
              <div class="d-flex flex-row align-items-center justify-content-center dkgreen-1">
                <FaCheck size={11} />{" "}
                <div class="ml-1">Password has 1 lowercase character</div>
              </div>
            ) : (
              <div class="d-flex flex-row align-items-center justify-content-center red-1">
                <FaTimes size={11} />{" "}
                <div class="ml-1">Password needs 1 lowercase character</div>
              </div>
            )}
          </div>
          <div class="password-attributes-message text-left">
            {/[A-Z]/.test(props.password) ? (
              <div class="d-flex flex-row align-items-center justify-content-center dkgreen-1">
                <FaCheck size={11} />{" "}
                <div class="ml-1">Password has 1 uppercase character</div>
              </div>
            ) : (
              <div class="d-flex flex-row align-items-center justify-content-center red-1">
                <FaTimes size={11} />{" "}
                <div class="ml-1">Password needs 1 uppercase character</div>
              </div>
            )}
          </div>
          <div class="password-attributes-message text-left">
            {/[0-9]/.test(props.password) ? (
              <div class="d-flex flex-row align-items-center justify-content-center dkgreen-1">
                <FaCheck size={11} />{" "}
                <div class="ml-1">Password has 1 number</div>
              </div>
            ) : (
              <div class="d-flex flex-row align-items-center justify-content-center red-1">
                <FaTimes size={11} />{" "}
                <div class="ml-1">Password needs 1 number</div>
              </div>
            )}
          </div>
          <div class="password-attributes-message text-left">
            {/[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`]/.test(props.password) ? (
              <div class="d-flex flex-row align-items-center justify-content-center dkgreen-1">
                <FaCheck size={11} />{" "}
                <div class="ml-1">Password has 1 special character</div>
              </div>
            ) : (
              <div class="d-flex flex-row align-items-center justify-content-center red-1">
                <FaTimes size={11} />{" "}
                <div class="ml-1">Password needs 1 special character</div>
              </div>
            )}
          </div>
        </div>
      </div>
    ) : null}
  </div>
)

export default Snippet
